<template>
  <div>
    <v-row>
      <v-col class="text-h1 mb-16 pb-16 text-center mt-16 pt-16">
        How To Apply
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {

  }
</script>

<style lang="scss" scoped>

</style>
